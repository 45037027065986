import { useState, useEffect } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { UPLOAD_URL, UPLOAD_TOKEN, BASE_URL } from "../../utils/env";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../Home/modal/Loader";

const ApplyJob = (props) => {
  const history = useHistory();
  const [jobData, setJobData] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    resume: "",
    fullName: "",
    email: "",
    phone: "",
    currentCompany: "",
    github: "",
    linkedin: "",
    portfolio: "",
    usaAuthorized: "yes",
    visaStatus: "yes",
    notes: "",
    jobId: props?.match?.params?.id,
  });

  const [picturesUploading, setPicturesUploading] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDrop = (picture) => {
    console.log("picture", picture);
    const pictureName = picture[0].name;
    var bodyFormData = new FormData();
    bodyFormData.append("target", picture[0]);
    setPicturesUploading(true);
    axios({
      method: "post",
      url: `${UPLOAD_URL}/users/upload`,
      data: bodyFormData,
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        accesstoken: UPLOAD_TOKEN,
      },
    })
      .then((res) => {
        setPicturesUploading(false);
        console.log("res", res);

        setData({ ...data, resume: res.data.url });
      })
      .catch((err) => {
        setPicturesUploading(false);
        console.log("err", err);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log(data);
    axios
      .post(`${BASE_URL}/application/register`, data)
      .then((res) => {
        console.log(res);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your application submitted successfully",
          text: "",
          showCloseButton: true,
        });
        history.push("/carrer");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showCloseButton: true,
        });
      });
  };

  console.log(props);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/jobs/${props?.match?.params?.id}`)
      .then((res) => {
        setJobData({ ...res.data });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section id="slide" class="slide page-breadcrumb">
        <div class="content-bottom"></div>
        <section
          id="team"
          class="our-team background-fullwidth background-fixed"
        >
          <div class="container">
            <div
              className="d-flex justify-content-between align-items-center py-2 carrer-item"
              style={{ width: "100%" }}
              role="button"
              onClick={() => {}}
            >
              <div>
                <h3>{jobData?.role}</h3>
                <h6 className="pt-2">{jobData?.location}</h6>
              </div>
            </div>

            <div>
              <h5 className="pt-5">SUBMIT YOUR APPLICATION</h5>

              <div>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Full Name"
                  className="mt-5 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="fullName"
                    type="text"
                    placeholder="Full name"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="email"
                    type="text"
                    placeholder="name@gmail.com"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mobile"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="phone"
                    type="text"
                    placeholder="Mobile"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Current Company"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="currentCompany"
                    type="text"
                    placeholder="Current Company"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Github"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="github"
                    type="text"
                    placeholder="Github"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Linkedin"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="linkedin"
                    type="text"
                    placeholder="Linkedin"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Portfolio"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="portfolio"
                    type="text"
                    placeholder="Portfolio"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Cover Letter"
                  className="mt-0 mb-4"
                >
                  <Form.Control
                    onChange={handleChange}
                    name="notes"
                    type="text"
                    placeholder="Cover Letter"
                  />
                </FloatingLabel>

                <div>
                  <ImageUploader
                    withIcon={false}
                    onChange={onDrop}
                    accept="*"
                    withLabel={false}
                    imgExtension={[]}
                    singleImage={true}
                    buttonStyles={{
                      padding: "15px 20px",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    buttonText="Add Resume"
                  />
                  {picturesUploading && <div class="loader">Uploading..</div>}
                  {data && data?.resume && (
                    <div style={{ margin: "20px 0" }} class="d-flex">
                      <div
                        style={{
                          border: "1px solid grey",
                          padding: "10px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          marginBottom: "20px",
                          marginRight: "20px",
                        }}
                        class="d-flex align-items-center justify-content-between"
                      >
                        <img
                          width={200}
                          height={200}
                          src={data.resume}
                          alt="res"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="pb-5">
                  <div class="btn-style-two" onClick={handleSubmit}>
                    {loading ? <Loader /> : <span>Apply</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ApplyJob;
