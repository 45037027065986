import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/env";

const JobDetails = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .get(`${BASE_URL}/jobs/${props?.match?.params?.id}`)
      .then((res) => {
        setData({ ...res.data });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(props);
  return (
    <div>
      <section id="slide" class="slide page-breadcrumb">
        <div class="content-bottom"></div>
        <section
          id="team"
          class="our-team background-fullwidth background-fixed"
        >
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 section-title">
                <div>
                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div>
                      <h3>{data?.role}</h3>
                      <h6 className="pt-2">{data?.location}</h6>
                    </div>
                    <div>
                      <Link
                        to={`/job/${data._id}/apply`}
                        style={{ textDecoration: "none", whiteSpace: "nowrap" }}
                      >
                        <a href="#" class="btn-style-two">
                          <span>Apply For This Job</span>
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="pt-3 pb-5 para"
                    dangerouslySetInnerHTML={{ __html: data?.body }}
                  ></div>
                  <Link
                    to={`/job/${data._id}/apply`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="pt-5 pb-5 text-center">
                      <a href="#" class="btn-style-two">
                        <span>Apply For This Job</span>
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default JobDetails;
