import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/env";

const Carrer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/jobs`)
      .then((res) => {
        setLoading(false);
        setData([...res.data.result]);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section id="slide" class="slide page-breadcrumb">
        <div class="content-bottom"></div>
        <section
          id="team"
          class="our-team background-fullwidth background-fixed"
        >
          <div class="container">
            <div class="row">
              <div class="text-center col-sm-12 col-md-12 col-lg-12 section-title">
                <h3>Career</h3>
                {/* <h3>
                  <span>Digital innovations to enable climate action</span>
                </h3> */}
              </div>
            </div>
            {loading && <div>Fetching Jobs</div>}
            <div className="carrer-list">
              {data &&
                data.map((ele) => (
                  <Link to={`/job/${ele._id}`}>
                    <div
                      className="d-flex justify-content-between align-items-center py-2 carrer-item"
                      style={{ width: "100%" }}
                      role="button"
                      onClick={() => {}}
                    >
                      <div>
                        <h3>{ele.role}</h3>
                        <h6>{ele.location}</h6>
                      </div>
                      <div>
                        <a href="#" class="btn-style-two">
                          <span>Apply</span>
                        </a>
                      </div>
                    </div>
                    <hr />
                  </Link>
                ))}
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Carrer;
