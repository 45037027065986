import React from "react";
import { TiTick } from "react-icons/ti";
import { BsCircleFill } from "react-icons/bs";
import HorizontalTimeline from "react-horizontal-timeline";

var today = new Date();

const EXAMPLE = [
  {
    data: new Date(),
    status: "status",
    statusB: "Admission Start",
    statusE: "Admission Open",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 5),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 10),
    status: "status",
    statusB: "Start 2nd round",
    statusE: "process",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 15),
    status: "status",
    statusB: "Start 3rd round",
    statusE: "Done",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 20),
    status: "status",
    statusB: "Admission Start",
    statusE: "Admission Open",
  },

  {
    data: new Date().setMinutes(today.getMinutes() - 25),
    status: "status",
    statusB: "Start 2nd round",
    statusE: "process",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 30),
    status: "status",
    statusB: "Start 3rd round",
    statusE: "Done",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 35),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 35),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 38),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 40),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 42),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 47),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 50),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
  {
    data: new Date().setMinutes(today.getMinutes() - 55),
    status: "status",
    statusB: "Start 1st round",
    statusE: "Open for Fillup",
  },
];

const timelineData = [
  {
    date: "Oct 2021",
    text: "Foundation",
  },
  {
    date: "Dec 2021",
    text: "Sensor Fusion Algorithms",
  },
  {
    date: "Jan 2022",
    text: "Transit Routing APIs",
  },
  {
    date: "Mar 2022",
    text: "iOS & Android Global Mining App",
  },
  {
    date: "May 2022",
    text: "AI verification Backend",
  },
  {
    date: "Jun 2022",
    text: "Subway Models",
  },
  {
    date: "Jul 2022",
    text: "Global Website",
  },
  {
    date: "Aug 2022",
    text: "Benefits Integration",
  },
  {
    date: "Sep 2022",
    text: "Payment Gateway Integration",
  },
  {
    date: "Oct 2022",
    text: "Security Module, Membership",
  },
  {
    date: "Q1 2023",
    text: "Independent Validation",
  },
  {
    date: "Q2 2023",
    text: "Smart Contract",
  },
  {
    date: "Q3 2023",
    text: "Liquidity Providers",
  },
  {
    date: "Q4 2023",
    text: "Bus Models",
    text1: "EV Models",
  },
  {
    date: "Q1 2024",
    text: "Corporate, OEM Partnerships",
  },
];
export default function Timeline() {
  const [period, setPeriod] = React.useState(1);
  const [curIdx, setCurIdx] = React.useState(7);
  const [prevIdx, setPrevIdx] = React.useState(-1);
  const [value, setValue] = React.useState(new Date());

  console.log("date", EXAMPLE.length, timelineData.length);
  return (
    <>
      <section
        id="feature-area"
        class="feature-area default-padding-bottom bottom-less"
      >
        <div class="text-center col-12 section-title" data-aos="fade-zoom-in">
          <h3>
            Time<span>line</span>
          </h3>
          <div class="space-25"></div>
        </div>
        <div>
          <div
            className="timeline"
            style={{
              width: "80%",
              height: "200px",
              margin: "0 auto",
              marginTop: "20px",
              fontSize: "35px",
            }}
          >
            <HorizontalTimeline
              labelWidth={200}
              linePadding={100}
              getLabel={(date, index) => {
                //console.log(date, index);
                return (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        left: 90,
                        top: 5,
                        zIndex: 1,
                        borderRadius: 50,
                      }}
                    >
                      <div
                        style={{
                          height: 20,
                          backgroundColor:
                            index === curIdx
                              ? "#244995"
                              : index < curIdx
                              ? "#244995"
                              : "#cbdafb",
                          borderRadius: 50,
                          width: 20,
                        }}
                      >
                        {index < curIdx ? (
                          <TiTick
                            style={{
                              marginTop: -40,
                              fontSize: 20,
                              color: "#cbdafb",
                            }}
                          />
                        ) : (
                          <BsCircleFill
                            style={{
                              marginTop: -39,
                              marginLeft: 0,
                              fontSize: 11,
                              color: index === curIdx ? "#cbdafb" : "#244995",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={`parent ${
                        curIdx === index ? "parent-active" : ""
                      }`}
                    >
                      <div className="child">
                        <div class="info">
                          <h4 style={{ marginBottom: 0 }}>
                            {timelineData[index].date}
                          </h4>
                        </div>
                        <p
                          className="text"
                          style={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          {timelineData[index].text}
                        </p>
                        {timelineData[index].text1 && (
                          <p className="text" style={{ paddingTop: 0 }}>
                            {timelineData[index].text1}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                );
              }}
              styles={{
                background: "#f8f8f8",
                foreground: "#007BFF",
                outline: "#007BFF",
                width: "100%",
              }}
              index={curIdx}
              indexClick={(index) => {}}
              values={EXAMPLE.map((x) => x.data)}
              //   isOpenBeginning={false}
              //   isOpenEnding={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}
