export default function Footer() {
  return (
    <section id="faq" class="faq">
      <div class="container">
        <div class="row">
          <div class="text-center col-12 section-title" data-aos="fade-zoom-in">
            <h3>
              Frequently Asked <span>Questions</span>
            </h3>
            <div class="space-25"></div>
            <div class="space-50"></div>
          </div>
        </div>
        <div class="row align-items-center faq-reverse">
          <div
            class="col-md-12 col-lg-4"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            <img
              src="assets/img/faq.jpeg"
              class="img-fluid b-block mx-auto"
              alt=""
            />
          </div>
          <div class="col-md-12 col-lg-8" data-aos="fade-right">
            <div class="accordion" id="faqAccordion">
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_1"
                      aria-expanded="false"
                      aria-controls="collapse_1"
                    >
                      Math behind carbon rewards?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_1"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      Each carbon reward represents 1 kg Co2 reduced or avoided.
                    </p>
                    <p>
                      For example, as per United States EPA, an average
                      passenger vehicle emits about 350 grams of CO2 per mile.
                      In contrast, moving in electric mass transit emits about{" "}
                      <a
                        target="_blank"
                        href="https://www.epa.gov/sites/default/files/2021-04/documents/emission-factors_apr2021.pdf"
                      >
                        50 grams
                      </a>{" "}
                      of Co2 per mile . Typically when members move 40km in
                      metro they save approximately a kg of Co2, and hence claim
                      1 carbon offset reward.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_2"
                      aria-expanded="false"
                      aria-controls="collapse_2"
                    >
                      What problem are we solving?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_2"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      No democratization of carbon markets, which are
                      unfortunately inaccessible for individuals today.
                    </p>
                    <p>
                      No incentive exists, to give instant feedback to
                      individuals for green action, and to do good for the
                      planet.
                    </p>
                    <p>
                      We are changing this through one system globally to make
                      this valuable and important market accessible to
                      individuals.{" "}
                    </p>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.spglobal.com/commodityinsights/en/market-insights/latest-news/energy-transition/110321-cop26-capital-committed-to-net-zero-emissions-surges-to-130-trillion-gfanz"
                      >
                        $130
                      </a>{" "}
                      trillion has been earmarked by tech companies, consumer
                      brands, oil majors, insurers, pension funds to reduce
                      carbon, to address the world's biggest challenge, climate
                      change and improve the standard of living.{" "}
                    </p>

                    <p>
                      Price of carbon reductions is growing and now{" "}
                      <a
                        target="_blank"
                        href="https://carboncredits.com/carbon-prices-today/"
                      >
                        $95
                      </a>{" "}
                      per ton on carbon exchanges. This equates to approximately
                      $10 cents per kg of Co2.
                    </p>

                    <p>
                      We believe that individuals are an integral part of the
                      equation to be net zero faster, and the O2 Club strives to
                      make these markets accessible to all using the latest
                      technology that provides transparency and consistency.{" "}
                    </p>
                    <p>
                      Last, but not the least, such system solves and addresses
                      multiple pains that the world is facing right now :
                      increased vehicle purchases and use, increased oil use,
                      road capital expediture requirement, arbitrary peak
                      pricing and tolls, arbitrary traffic rationalisation rules
                      such as "odd-even", lower footfall for local businesses
                      and last mile providers at public transit locations, lower
                      traffic and financial unviability of public transport
                      systems.{" "}
                    </p>
                    <p>
                      Vision is to have a system that ensures that if people do
                      actions good for their city, communities and the planet,
                      the actions will pay off individually anyway, and
                      collectively pay off in multiple of that value.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_3"
                      aria-expanded="false"
                      aria-controls="collapse_3"
                    >
                      The team and community?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_3"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      A stellar team that combines tech leadership from the Bay
                      Area in the USA, with the expertise of building mobility
                      and mobile technology at Apple, Tesla and similar
                      companies; and green experts that have been responsible
                      for delivering de-carbon deals over decades in both large
                      developed and emerging markets across the globe. They
                      comprise young and the experienced and have the
                      recognition of the community partners and investors.
                    </p>
                    <p>
                      They are committed to reduce carbon emissions, traffic,
                      pollution and promote local economies, employment and
                      livelihoods while making green actions exciting. Its
                      typical for our members to quip about a eureka moment when
                      they committed to the energy and excitement that green
                      technology can bring.{" "}
                    </p>
                    <p>
                      We encourage you to write in to us at info@o2club.org if
                      you'd like to join in any role. Motivation and problem
                      solving is key.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_4"
                      aria-expanded="false"
                      aria-controls="collapse_4"
                    >
                      How do we monetise the carbon rewards?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_4"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      Individuals are rewarded for each co2 kg reduced.
                      Businesses do not get the offsets, other than by provide
                      offerings to the individuals to get access to the offsets
                      at the O2 Club.
                    </p>
                    <p>
                      Leading future and consumer oriented brands, insurers,
                      businesses (that benefit from footfalls along mass transit
                      systems), governments approach the O2 Club to provide
                      deals to privileged members.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_5"
                      aria-expanded="false"
                      aria-controls="collapse_5"
                    >
                      What are Carbon Credits?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_5"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      Carbon credit is the generic term to assign a value to a
                      reduction or offset or avoidance of greenhouse gas
                      emissions, usually equivalent to one tonne of carbon
                      dioxide equivalent. Companies buy to negate unavoidable
                      emissions, and/ or for branding as they have to move to
                      net zero carbon. Tech firms, Data centres, Airlines, Auto
                      companies, Oil majors, Consumer brands typically buy
                      these.
                    </p>
                    <p>
                      Market has surged to{" "}
                      <a
                        target="_blank"
                        href="https://www.reuters.com/business/energy/global-carbon-markets-value-surged-record-851-bln-last-year-refinitiv-2022-01-31/"
                      >
                        $851
                      </a>{" "}
                      bn and growing rapidly.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_6"
                      aria-expanded="false"
                      aria-controls="collapse_6"
                    >
                      Why are we using Blockchain?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_6"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      Transparency and consistency of incentives with one system
                      globally.
                    </p>
                    <p>
                      O2Club's blockchain solution creates an unalterable record
                      of transactions with end-to-end encryption, making it
                      harder to fraud and unauthorized activity. Additionally,
                      data on the blockchain is stored across a network of
                      computers, making it nearly impossible to hack. This makes
                      the system reliable and easy to understand for users and
                      purchasers of the carbon rewards.{" "}
                    </p>
                    <p>
                      One of the biggest challenges as outlined by the{" "}
                      <a
                        target="_blank"
                        href="https://www.oecd.org/stories/climate-25-actions/index.html#element-5382"
                      >
                        OECD
                      </a>{" "}
                      is making the capital reach the right places fast and
                      consistently to induce fast action. Blockchain is the
                      solution that promises to bridge the world with one
                      transparent common ledger that cannot be manipulated.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-header" id="heading_2">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse_7"
                      aria-expanded="false"
                      aria-controls="collapse_7"
                    >
                      Why is there a membership fee?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse_7"
                  class="collapse"
                  aria-labelledby="heading_2"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    <p>
                      Joining is free as a member to earn upto 3 carbon rewards
                      per day. However, to earn upto 10 carbon rewards, there is
                      a nominal charge of $45.9 per 52 weeks (less than
                      $1/week). This is free by invitation currently for limited
                      number of users per week, that have demonstrated
                      commitment to green actions and referred by existing
                      members.{" "}
                    </p>
                    <p>
                      This fees helps cover increased usage and development
                      costs for the blockchain system.
                    </p>
                    <p>
                      The value delivered to the user in turn is higher than
                      this fee as they can monetise upto 3.65 ton Co2 reduced
                      per annum.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
