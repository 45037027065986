import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/env";

const Partners = (props) => {
  const data = [
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image2.jpeg",
    },
    {
      image: "assets/partners/image3.jpeg",
    },
    {
      image: "assets/partners/image4.jpeg",
    },
    {
      image: "assets/partners/image5.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
    {
      image: "assets/partners/image1.jpeg",
    },
  ];
  return (
    <div>
      <section id="slide" class="slide page-breadcrumb">
        <div class="content-bottom"></div>
        <section
          id="team"
          class="our-team background-fullwidth background-fixed"
        >
          <div class="container">
            <div class="row">
              <div class="text-center col-sm-12 col-md-12 col-lg-12 section-title">
                <h3>
                  Our Partners & <span>Carbon Investors</span>{" "}
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section
          id="about"
          class="why-us SecBlockChains SecBlockChain padding-100 background-fullwidth background-fixed "
          style={{ backgroundImage: "url(assets/img/gray-bg1.jpg)" }}
        >
          <div class="container">
            <div class="row align-items-center">
              {data &&
                data.map((ele, index) => (
                  <div
                    class="col-sm-12 col-md-12 col-lg-2 text-center mb-5"
                    data-aos="fade-right"
                    style={{ color: "#000" }}
                  >
                    <img
                      src={`assets/partners/image${index + 1}.jpeg`}
                      class="img-fluid"
                      style={{
                        width: "60%",
                        borderRadius: 10,
                        filter: "blur(5px)",
                      }}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Partners;
